import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import SupportiveCareSubmenu from "../../components/template-partials/supportive-care/supportive-care-submenu"
import SupportiveCareFooterLinks from "../../components/template-partials/supportive-care/supportive-care-footer-links"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"


import headingBg from "../../images/4.0-headerbg.jpg"
import headingRound from "../../images/supportive-care-nav-image.jpg"
import AlertInfoCard from "../../components/template-partials/global-components/alert-infocard"
import noCooking from "../../images/4.2_nocooking.jpg";
import blandFoods from "../../images/4.1_blandfoods.jpg";
import greasyFoods from "../../images/4.1_avoidgreasyfoods.jpg";
import popicles from "../../images/4.1_popsicles.jpg";
import eatSlowly from "../../images/4.1_eatslowly.jpg";
import antinausea from "../../images/4.1_antinauseameds.jpg";
import medication from "../../images/4.3.1_medication.png";

import signsSymptoms from "../../images/4.2-signs-symtoms.jpg"
import treatingnav from "../../images/4.2-treating.jpg"

import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"


//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const NauseaVomitingPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: 'unslick' },
      { breakpoint: 1024, settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }},
    ]
  };
  return (
    <Layout>
      <SEO title="Neuroblastoma Supportive Care | Nausea & Vomiting" description="Nausea and vomiting are some of the most common side effects of neuroblastoma treatment, and symptom severity can vary depending on treatment type. Find out some common ways to help manage these side effects." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Nausea and Vomiting">
        <h1 className="section__heading section__heading--green h1">Nausea <em>and</em> Vomiting</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Supportive Care`}>
              <SupportiveCareSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p><Link rel="glossary" to="/support-and-community/glossary/#nausea">Nausea</Link> and vomiting are 2 common side effects of neuroblastoma treatment. Thankfully, there are many ways you can help prevent and control nausea and vomiting. </p>
                <h2 className="tc-barney h2 jost-med">Potential causes of nausea and vomiting</h2>
                <ul className="ul--dotted lg_mt-1">
                  <li><Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">Chemotherapy</Link></li>
                  <li>Radiation therapy</li>
                  <li><Link rel="glossary" to="/support-and-community/glossary/#antibody-therapy">Antibody therapy</Link></li>
                  <li>Other medications (such as, antibiotics and opioids)</li>
                  <li><Link rel="glossary" to="/support-and-community/glossary/#constipation">Constipation</Link></li>
                  <li>Anxiety</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="tc-barney h2 jost-med">Signs and symptoms of nausea and vomiting</h3>
            <p><Link rel="glossary" to="/support-and-community/glossary/#nausea">Nausea</Link> is when your child feels sick to their stomach. Nausea can sometimes lead to vomiting or throwing up. </p>
            <p>The level of nausea your child may feel can vary. Nausea can range from mild to severe and will depend on the type of treatment your child receives. </p>
          </div>
          <div className="columns large-4 small-12 text-center large-text-right">
            <FancyImage url={signsSymptoms} alt="signs and symptoms of nausea and vomiting"/>
          </div>
        </div>
        <InlineInfocard className="lg_mt-2 mt-3" text="For many children with neuroblastoma, nausea will be one of the side effects that is most upsetting. If not managed, nausea and vomiting can lead to poor nutrition, weight loss, and trouble taking oral medication, and can interfere with your child’s normal daily activities."/>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns large-4 small-12 text-center large-text-left">
            <FancyImage url={treatingnav} alt="treating nausea and vomiting"/>
          </div>
          <div className="columns">
            <h3 className="tc-barney h2 jost-med">Treating nausea and vomiting </h3>
            <p>Your child’s healthcare team will work closely with you to develop a plan to help manage <Link rel="glossary" to="/support-and-community/glossary/#nausea">nausea</Link> and vomiting for your child. The goal will be to prevent nausea and vomiting if possible, or to be able to treat it if it occurs. A few common methods to help manage nausea and vomiting are given below.</p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-4 mt-4">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Antiemetics (antinausea medicines)</h4>
            <p>There are many different types of antinausea medicines, and your child may be given a combination of medicines depending on their situation. They can be given by mouth (oral), <Link rel="glossary" to="/support-and-community/glossary/#intravenously">IV</Link>, or a patch on the skin (transdermal). Some common antinausea medicines used in childhood cancer treatment are: </p>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ul className="ul--dotted">
                  <li>Ondansetron</li>
                  <li>Granisetron</li>
                  <li>Lorazepam</li>
                  <li>Diphenhydramine</li>
                  <li>Dexamethasone</li>
                </ul>
              </div>
              <div className="columns">
                <ul className="ul--dotted">
                  <li>Fosaprepitant</li>
                  <li>Metoclopramide</li>
                  <li>Olanzapine</li>
                  <li>Scopolamine</li>
                </ul>
              </div>
            </div>
            <p>Many of the medicines used to help with nausea and vomiting in childhood cancer have other common uses. Make sure to talk to your healthcare team if you have specific questions about antinausea medicines for your child. </p>
            <p><strong>It will be important to let your healthcare provider know if your child has nausea or vomiting at home after <Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">chemotherapy</Link> so additional medicines may be given. </strong></p>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns">
            <h4 className="tc-viridian h4 jost-med">Changes to diet </h4>
            <p>During treatment, your child may experience a loss of appetite when they are nauseous. They may also feel nauseous around certain foods or smells. This can vary each day and may depend on the phase of therapy they are in. When your child is having nausea, you can:</p>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4 mb-4">
          <div className="columns">
            <Slider className="grid-slider" {...settings}>
              <div className="text-center">
                <img src={eatSlowly} alt="" style={{maxWidth:`100px`}}/>
                <p className="mt-2">Encourage them to eat small meals slowly throughout the day</p>
              </div>
              <div className="text-center">
                <img src={popicles} alt="" style={{maxWidth:`88px`}}/>
                <p className="mt-2">Offer hard candy, ice pops, or ice for  them to suck on</p>
              </div>
              <div className="text-center">
                <img src={greasyFoods} alt="" style={{maxWidth:`84px`}}/>
                <p className="mt-2">Help them avoid greasy, fried, very sweet, or spicy foods</p>
              </div>
              <div className="text-center">
                <img src={blandFoods} alt="" style={{maxWidth:`85px`}}/>
                <p className="mt-2">Provide bland foods that are easy to digest like crackers, rice, bread, and pasta</p>
              </div>
              <div className="text-center">
                <img src={noCooking} alt="" style={{maxWidth:`82px`}}/>
                <p className="mt-2">Place them in a room free from cooking or other smells</p>
              </div>
              <div className="text-center">
                <img src={antinausea} alt="" style={{maxWidth:`66px`}}/>
                <p className="mt-2">Give antinausea medicine at the first sign of nausea, as suggested by your child’s doctor</p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="row row--inner lg_mt-2">
          <div className="columns">
            <p><Link to="/supportive-care/diet-nutrition/">Find more information on nutrition during neuroblastoma treatment. </Link></p>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.honeydew}}>
        <div className="row row--inner align-justify small-col-reverse">
          <div className="columns mt-2">
            <h3 className="tc-barney h2 jost-med">Managing nausea and vomiting at home</h3>
            <p>After your child’s treatment, you will be sent home with antinausea medicines for your child to take by mouth. Your child’s healthcare team will talk with you about when and how often to use them based on your child’s symptoms. Some may be given on a schedule and others given only when needed to help with <Link rel="glossary" to="/support-and-community/glossary/#nausea">nausea</Link> that lasts after a visit to the hospital. </p>
            <p>When your child is nauseous, encourage them to take small, but frequent sips of water (or other fluids) and small bites of bland foods. If your child is not drinking enough water, they will be at risk for dehydration. Signs your child may be dehydrated are: </p>
            <ul className="ul--dotted">
              <li>Peeing less than normal or having dark urine</li>
              <li>No tears when crying</li>
              <li>Dry lips</li>
              <li>Not able to drink fluids without throwing up</li>
            </ul>
          </div>
          <div className="columns large-3 small-12 text-center large-text-right">
            <img src={medication} style={{maxWidth:`191px`}} alt=""/>
          </div>
        </div>
        <AlertInfoCard className="lg_mt-2" text="Please call your child’s healthcare team if you have any concerns about dehydration or if your child is unable to eat, drink, or take oral medications without throwing up."/>
      </Section>

      <Section>
        <SupportiveCareFooterLinks/>
      </Section>

      <InteriorPageFooter>

      <UpNextCard topic="Constipation and Diarrhea" title="Constipation and diarrhea" lead="Learn about the potential for constipation and diarrhea, and how they are managed." url="/supportive-care/constipation-diarrhea/" />

      </InteriorPageFooter>

    </Layout>
  )
}

export default NauseaVomitingPage
